import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _0fe3412a = () => interopDefault(import('../pages/about-us.vue' /* webpackChunkName: "pages/about-us" */))
const _2672031a = () => interopDefault(import('../pages/boutiques.vue' /* webpackChunkName: "pages/boutiques" */))
const _45870f28 = () => interopDefault(import('../pages/cart.vue' /* webpackChunkName: "pages/cart" */))
const _946fd206 = () => interopDefault(import('../pages/catalog.vue' /* webpackChunkName: "pages/catalog" */))
const _bd1964dc = () => interopDefault(import('../pages/checkout.vue' /* webpackChunkName: "pages/checkout" */))
const _d109dbc2 = () => interopDefault(import('../pages/contacts.vue' /* webpackChunkName: "pages/contacts" */))
const _6364b599 = () => interopDefault(import('../pages/sitemap.vue' /* webpackChunkName: "pages/sitemap" */))
const _b3901c32 = () => interopDefault(import('../pages/success.vue' /* webpackChunkName: "pages/success" */))
const _2daf0eba = () => interopDefault(import('../pages/product-category/_slug.vue' /* webpackChunkName: "pages/product-category/_slug" */))
const _1ffa3864 = () => interopDefault(import('../pages/product/_slug.vue' /* webpackChunkName: "pages/product/_slug" */))
const _dfc2c394 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _6d5ace1e = () => interopDefault(import('../pages/_slug/index.vue' /* webpackChunkName: "pages/_slug/index" */))

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about-us",
    component: _0fe3412a,
    name: "about-us___en"
  }, {
    path: "/ru/about-us",
    component: _0fe3412a,
    name: "about-us___ru"
  }, {
    path: "/uk/about-us",
    component: _0fe3412a,
    name: "about-us___uk"
  }, {
    path: "/boutiques",
    component: _2672031a,
    name: "boutiques___en"
  }, {
    path: "/ru/boutiques",
    component: _2672031a,
    name: "boutiques___ru"
  }, {
    path: "/uk/boutiques",
    component: _2672031a,
    name: "boutiques___uk"
  }, {
    path: "/cart",
    component: _45870f28,
    name: "cart___en"
  }, {
    path: "/ru/cart",
    component: _45870f28,
    name: "cart___ru"
  }, {
    path: "/uk/cart",
    component: _45870f28,
    name: "cart___uk"
  }, {
    path: "/catalog",
    component: _946fd206,
    name: "catalog___en"
  }, {
    path: "/ru/catalog",
    component: _946fd206,
    name: "catalog___ru"
  }, {
    path: "/uk/catalog",
    component: _946fd206,
    name: "catalog___uk"
  }, {
    path: "/checkout",
    component: _bd1964dc,
    name: "checkout___en"
  }, {
    path: "/ru/checkout",
    component: _bd1964dc,
    name: "checkout___ru"
  }, {
    path: "/uk/checkout",
    component: _bd1964dc,
    name: "checkout___uk"
  }, {
    path: "/contacts",
    component: _d109dbc2,
    name: "contacts___en"
  }, {
    path: "/ru/contacts",
    component: _d109dbc2,
    name: "contacts___ru"
  }, {
    path: "/uk/contacts",
    component: _d109dbc2,
    name: "contacts___uk"
  }, {
    path: "/sitemap",
    component: _6364b599,
    name: "sitemap___en"
  }, {
    path: "/ru/sitemap",
    component: _6364b599,
    name: "sitemap___ru"
  }, {
    path: "/uk/sitemap",
    component: _6364b599,
    name: "sitemap___uk"
  }, {
    path: "/success",
    component: _b3901c32,
    name: "success___en"
  }, {
    path: "/ru/success",
    component: _b3901c32,
    name: "success___ru"
  }, {
    path: "/uk/success",
    component: _b3901c32,
    name: "success___uk"
  }, {
    path: "/product-category/:slug?",
    component: _2daf0eba,
    name: "product-category-slug___en"
  }, {
    path: "/ru/product-category/:slug?",
    component: _2daf0eba,
    name: "product-category-slug___ru"
  }, {
    path: "/uk/product-category/:slug?",
    component: _2daf0eba,
    name: "product-category-slug___uk"
  }, {
    path: "/product/:slug?",
    component: _1ffa3864,
    name: "product-slug___en"
  }, {
    path: "/ru/product/:slug?",
    component: _1ffa3864,
    name: "product-slug___ru"
  }, {
    path: "/uk/product/:slug?",
    component: _1ffa3864,
    name: "product-slug___uk"
  }, {
    path: "/",
    component: _dfc2c394,
    name: "index___en"
  }, {
    path: "/ru/",
    component: _dfc2c394,
    name: "index___ru"
  }, {
    path: "/uk/",
    component: _dfc2c394,
    name: "index___uk"
  }, {
    path: "/:slug",
    component: _6d5ace1e,
    name: "slug___en"
  }, {
    path: "/ru/:slug",
    component: _6d5ace1e,
    name: "slug___ru"
  }, {
    path: "/uk/:slug",
    component: _6d5ace1e,
    name: "slug___uk"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
