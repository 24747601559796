<template lang='pug'>
  footer.main-footer
    .container
      .main-footer-info(v-if="!excludeFromPage")
        .main-footer-info__title {{ staticContent.title }}
        .main-footer-info__row
          .main-footer-info__col
            .info-block
              .info-block__title {{ staticContent.delivery.title }}
              .info-block__descr(v-html="staticContent.delivery.descr")
          .main-footer-info__col
            .info-block
              .info-block__title {{ staticContent.warranty.title }}
              .info-block__descr(v-html="staticContent.warranty.descr")

      .main-footer-bottom
        .main-footer-bottom__row
          .main-footer-bottom__col
            .main-footer-payment
              .main-footer-payment__item
                svg-icon.icon-visa(name="visa")
              .main-footer-payment__item
                svg-icon.icon-mastercard(name="mastercard")
          FooterMenu
          .main-footer-bottom__col.ml-a
            .main-footer-copyright
              p SITELLE Store &copy; {{ new Date().getFullYear() }}
          .main-footer-bottom__col.main-footer-bottom__col--small
            .main-footer-development
              a(href="//point-agency.net/" target="_blank")
                svg-icon.icon-point-logo(name="point-logo")
</template>

<script>
import { mapState } from 'vuex'
import FooterMenu from '@/components/menus/FooterMenu'

export default {
  components: { FooterMenu },

  computed: {
    ...mapState({
      staticContent: state => state.content.data.footer_content
		}),
		
		excludeFromPage() {
			let excludeFromPages = ['cart','checkout','product'],
				excludeFromPage = false;

			excludeFromPages.map(page => {
				if (excludeFromPage == false && this.$route.path.indexOf(page) > 0)
					excludeFromPage = true
			})
			
			return excludeFromPage;
		}
	},
}
</script>

<style lang="scss">
@import '~assets/scss/helpers/vars';
@import '~assets/scss/helpers/mixins';

.main-footer {
  padding: 150px 0 20px;
  background-color: $yellowColor;

  @include tablet {
    padding-top: 70px;
  }

  @include mobile {
    padding-top: 30px;
  }

  .main-footer-info {
    width: 100%;
    max-width: 770px;
    margin-left: auto;
    margin-bottom: 205px;

    @include tablet {
      max-width: 100%;
      margin-bottom: 100px;
    }

    @include mobile {
      margin-bottom: 30px;
    }

    &__title {
      margin: 0;
      margin-bottom: 15px;
      font-size: 12px;
      font-weight: 500;
      line-height: 1.35;
      text-transform: uppercase;

      @include mobile {
        margin-bottom: 20px;
      }
    }

    &__row {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -20px;

      @include desktop {
        margin: 0 -15px;
      }
    }

    &__col {
      flex: 0 0 50%;
      max-width: 50%;
      padding: 0 20px;

      @include desktop {
        padding: 0 15px;
      }

      @include mobile {
        flex: 0 0 100%;
        max-width: 100%;
        margin-bottom: 30px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .info-block {
    &__title {
      margin: 0;
      margin-bottom: 25px;
      color: $blackColor;
      font-size: 28px;
      font-weight: 500;
      line-height: 1.45;

      @include tablet {
        margin-bottom: 20px;
        font-size: 26px;
      }

      @include mobile {
        font-size: 22px;
      }
    }
  }

  .main-footer-bottom {
    width: 100%;
    max-width: 980px;
    margin-left: auto;

    &__row {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-end;
      margin: 0 -20px;

      @include desktop {
        margin: 0 -15px;
      }

      @include mobile {
        margin: 0;
      }
    }

    &__col {
      flex: 0 0 calc(170px + 40px);
      max-width: calc(170px + 40px);
      padding: 0 20px;

      @include desktop {
        padding: 0 15px;
      }

      @include mobile {
        flex: 1;
        max-width: none;
        padding: 0;
      }

      &--thumb {
        display: flex;
        flex-wrap: wrap;
        flex: 0 0 calc(420px + 40px);
        max-width: calc(420px + 40px);
        margin: 0 -20px;

        @include desktop {
          margin: 0 -15px;
        }

        @include tablet {
          order: -1;
          flex: 0 0 100%;
          max-width: 100%;
          margin-bottom: 30px;
        }

        @include mobile {
          margin: 0 0 30px;
        }
      }

      &--small {
        flex: 0 0 calc(50px + 40px);
        max-width: calc(50px + 40px);

        @include mobile {
          flex: 0 0 calc(40px + 30px);
          max-width: calc(40px + 30px);
        }
      }
    }
  }

  .main-footer-payment {
    display: flex;
    align-items: center;

    &__item {
      margin-right: 15px;
      line-height: 0;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .main-footer-nav {
    flex: 0 0 calc(170px + 40px);
    max-width: calc(170px + 40px);
    padding: 0 20px;

    @include desktop {
      padding: 0 15px;
    }

    @include tablet {
      flex: 0 0 50%;
      max-width: 50%;
    }

    @include mobile {
      padding: 0;
    }

    &__title {
      margin: 0;
      margin-bottom: 15px;
      font-size: 18px;
      line-height: 1.25;

      @include mobile {
        font-size: 16px;
      }
    }

    &__list-item {
      margin-bottom: 10px;
      line-height: 1.25;

      @include mobile {
        margin-bottom: 5px;
        font-size: 12px;
      }

      &:last-child {
        margin-bottom: 0;
      }

      a {
        display: inline-block;
        line-height: inherit;
      }
    }
  }

  .main-footer-copyright {
    text-align: right;
    color: rgba($blackColor, 0.6);
    font-size: 12px;
    line-height: 1.2;

    @include mobile {
      font-size: 10px;
    }
  }

  .main-footer-development {
    text-align: right;
    line-height: 0;
  }
}
</style>
