export const state = () => ({
  data: {}
})

export const getters = {}

export const mutations = {
  SET_CONTENT(state, payload) {
    state.data = payload
  }
}

export const actions = {}
