<template lang="pug">
  transition(name="page")
    .s-modal.country-modal(v-if="showCountryModal")
      .s-modal__overlay(@click="closeModal()")
      .s-modal__wrapper
        .s-modal__close(@click="closeModal()")
          svg-icon.icon-close-cursor(name="close-cursor")

        h2.s-modal__title Choose your country

        ul.country-modal__list
          li.item(v-for="(item, index) in list" :key="index")
            a.link(v-if="$cookies.get('country') !== item.country"
                   @click="setCookies(item.country, item.locale, item.currency)"
                   :href="switchLocalePath(item.locale)") {{ item.country }}
            span.selected(v-else) {{ item.country }}

        .country-modal__other
          a.link(v-if="$cookies.get('country') !== 'Country'"
                 :href="switchLocalePath('en')" @click="setCookies('Country', 'en', 'none')") Other locations (EN)
          span.selected(v-else) Other locations (EN)
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import countriesList from '@/assets/json/countries.json'

export default {
  data() {
    return {
      list: countriesList
    }
  },

  computed: {
    ...mapState(['showCountryModal'])
  },

  watch: {
    $route: function() {
      if (this.showCountryModal) {
        this.closeModal()
      }
    }
  },

  methods: {
    ...mapMutations({
      closeModal: 'TOOGLE_COUNTRY_MODAL'
    }),

    setCookies(country, locale, currency) {
      const cookieList = [
        { name: 'country', value: country },
        { name: 'locale', value: locale },
        { name: 'currency', value: currency }
      ]

      this.$cookies.setAll(cookieList)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~assets/scss/helpers/vars';
@import '~assets/scss/helpers/mixins';

.country-modal {
  &__list {
    columns: 3 auto;
    margin-bottom: 30px;

    li {
      margin-bottom: 10px;
    }

    a {
      color: $blackColor;
      font-size: 16px;
      font-weight: 500;
      border-bottom: 1px solid transparent;
      transition: border-color 0.3s;

      @include mobile {
        font-size: 12px;
      }

      &:hover {
        border-color: $greyColor;
      }
    }

    span {
      color: $yellowColor;
      font-size: 16px;
      font-weight: 500;

      @include mobile {
        font-size: 12px;
      }
    }
  }

  &__other {
    a {
      color: $blackColor;
      font-size: 16px;
      font-weight: 500;
      border-bottom: 1px solid transparent;
      transition: border-color 0.3s;

      @include mobile {
        font-size: 12px;
      }

      &:hover {
        border-color: $greyColor;
      }
    }

    span {
      color: $yellowColor;
      font-size: 16px;
      font-weight: 500;

      @include mobile {
        font-size: 12px;
      }
    }
  }
}
</style>
