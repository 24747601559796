<template lang="pug">
  a.scroll-top(v-show-on-scroll @click.prevent="scrollToTop()")
    span.text {{ $t('sidebar.toTop') }}
    span.icon
      svg-icon.icon-totop(name="to-top")
</template>

<script>
export default {
  methods: {
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }
  }
}
</script>

<style lang="scss">
@import '~assets/scss/helpers/vars';
@import '~assets/scss/helpers/mixins';

.scroll-top {
  display: flex;
  align-items: center;
  margin-top: 10px;
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;

  &.show {
    opacity: 1;
    visibility: visible;
  }

  @include tablet {
    position: fixed;
    bottom: 50px;
    right: 15px;
    justify-content: center;
    width: 30px;
    height: 30px;
    margin-top: 0;
    border-radius: 50%;
    background-color: $yellowColor;
    box-shadow: 0 2px 8px rgba(29, 19, 0, 0.15);
  }

  .text {
    color: $blackColor;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.2;

    @include tablet {
      display: none;
    }
  }

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 3px;
    line-height: 1;

    @include tablet {
      margin-left: 0;
    }
  }
}
</style>
