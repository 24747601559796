<template lang="pug">
  .socials-menu
    ul.socials-menu__list
      li(v-for="social in staticContent")
        a(:href="social.link" rel="nofollow" target="_blank") {{social.title}}
      li(v-if="$mq === 'mobile' || $mq === 'mobileSmall' || $mq === 'tablet'")
        a.phone(v-if="$cookies.get('locale') != 'en'" href="tel: +38 097 369 09 36") +38 097 369 09 36
      li(v-if="$mq === 'mobile' || $mq === 'mobileSmall' || $mq === 'tablet'")
        a.mail(href="mailto: contact@sitelle.ua") contact@sitelle.ua

</template>

<script>
import { mapState } from 'vuex'

export default {

  computed: {
    ...mapState({
      staticContent: state => state.content.data.contacts.socials
    })
  }
}
</script>

<style lang="scss" scoped>
@import '~assets/scss/helpers/vars';

.socials-menu {
  &__list {
    li {
      margin-bottom: 10px;
      line-height: 1;

      &:last-child {
        margin-bottom: 0;
      }
    }

    a {
      display: inline-block;
      color: $blackColor;
      font-size: 12px;
      font-weight: 500;
      line-height: 1.2;
      &.phone,
      &.mail {
        position: relative;
        margin-left: 25px;
        &:before {
          content: '';
          position: absolute;
          left: -25px;
          top: 50%;
          transform: translate(0,-50%);
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          display: block;
          width: 15px;
          height: 15px;
        }
      }
      &.phone {
        &:before {
          background-image: url(/img/phone-icon.svg);
        }
      }
      &.mail {
        &:before {
          background-image: url(/img/mail-icon.svg);
        }
      }
    }
  }
}
</style>
