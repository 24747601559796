<template lang="pug">
  .error-page
    .error-page__wrapper
      .error-page__logo
        img(src="/img/404.svg" :alt="$t('pages.error.title')")
      h1.error-page__title {{ $t('pages.error.title') }}
      .error-page__descr
        p {{ $t('pages.error.descr') }}
      nuxt-link.btn.error-page__btn(:to="localePath('index') + '/'") {{ $t('links.goToHomepage') }}
</template>

<script>
export default {
  layout: 'empty',

  props: {
    error: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~assets/scss/helpers/vars';
@import '~assets/scss/helpers/mixins';

.error-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 80px 0 240px;

  @include mobile {
    align-items: flex-start;
  }

  &__wrapper {
    max-width: 630px;
    margin: 0 auto;
    text-align: center;
  }

  &__logo {
    max-width: 235px;
    margin: 0 auto 60px;

    @include laptop {
      margin-bottom: 50px;
    }

    @include tablet {
      max-width: 200px;
      margin-bottom: 40px;
    }

    @include mobile {
      max-width: 175px;
      margin-bottom: 30px;
    }
  }

  &__title {
    margin-bottom: 30px;
    font-size: 36px;
    font-weight: 500;
    line-height: 1.12;

    @include laptop {
      font-size: 32px;
    }

    @include tablet {
      margin-bottom: 20px;
      font-size: 28px;
    }

    @include mobile {
      font-size: 24px;
    }
  }

  &__descr {
    margin-bottom: 30px;
    font-size: 18px;
    line-height: 1.8;

    @include tablet {
      font-size: 16px;
      line-height: 1.6;
    }

    @include mobile {
      font-size: 14px;
    }
  }

  &__btn {
    max-width: 300px;
    margin: 0 auto;
  }
}
</style>
