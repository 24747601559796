<template lang="pug">
  aside.main-sidebar(:class="{ open: showMobileMenu }")
    header.main-sidebar__header
      .links
        .lang
          template(v-if="$cookies.get('country') === 'Ukraine'")
            template(v-if="$cookies.get('locale') === 'uk'")
              span.lang__link.active UA
            template(v-else)
              a.lang__link(:href="switchLocalePath('uk')  + '/'" @click="$cookies.set('locale', 'uk')") UA

            template(v-if="$cookies.get('locale') === 'ru'")
              span.lang__link.active RU
            template(v-else)
              a.lang__link(:href="switchLocalePath('ru') + '/'" @click="$cookies.set('locale', 'ru')") RU
          template(v-else)
            span.main-header-lang__link.active EN

        .location
          span(v-if="$cookies.get('country')"
              @click="openCountryModal()") {{ $cookies.get('country') }}
          span(v-else
              @click="openCountryModal()") Country

      .close(@click="closeMobileMenu()") {{ $t('mobileMenu.close') }}
    MainMenu
    SocialMenu
    ToTop
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import MainMenu from '@/components/menus/MainMenu'
import SocialMenu from '@/components/menus/SocialMenu'
import ToTop from '@/components/misc/ToTop'

export default {
  components: { MainMenu, SocialMenu, ToTop },

  computed: {
    ...mapState({
      showMobileMenu: state => state.showMobileMenu
    })
  },

  watch: {
    $route: function() {
      if (this.showMobileMenu) {
        this.closeMobileMenu()
      }
    }
  },

  methods: {
    ...mapMutations({
      closeMobileMenu: 'TOOGLE_MOBILE_MENU',
      openCountryModal: 'TOOGLE_COUNTRY_MODAL'
    })
  }
}
</script>

<style lang="scss" scoped>
@import '~assets/scss/helpers/vars';
@import '~assets/scss/helpers/mixins';

.main-sidebar {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: calc(100% - 80px);
  width: 150px;
  margin-right: 30px;
  padding-bottom: 20px;

  @include laptop {
    padding-bottom: 50px;
  }

  @include tablet {
    z-index: 1000;
    top: 3px;
    left: -320px;
    width: 320px;
    height: 100vh;
    margin: 0;
    padding: 30px 15px;
    background-color: $yellowColor;
    transition: left 0.3s linear;
    overflow-y: auto;

    &.open {
      left: 0;
    }
  }

  &__header {
    display: none;
    justify-content: space-between;
    align-items: center;

    .links {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }

    .lang,
    .location {
      border-bottom: 1px solid $greyColor;

      span {
        font-size: 12px;
        font-weight: 500;
        line-height: 1.6;
      }
    }

    .lang {
      margin-right: 5px;
    }

    .close {
      font-size: 12px;
      text-transform: capitalize;
      text-decoration: underline;
      cursor: pointer;
    }

    @include tablet {
      display: flex;
    }
  }
}

.no-sidebar .main-sidebar {
  display: none;

  @include tablet {
    display: flex;
  }
}

.main-menu,
.socials-menu {
  margin-top: auto;
}
</style>
