export default function ({ isHMR, app, store, route, params, req, error, redirect }) {

	if(/\.html$/.test(route.path)) return;

	/**
	 * Редирект на слеш в конце
	 */
	let pathWithSlash = route.fullPath.replace(/\/?$/, '/')
	let pathWithGet = route.fullPath.split('?')[0]

	if (pathWithSlash !== route.fullPath && route.fullPath === pathWithGet) {
    	return redirect(301, pathWithSlash)
	}
	
}