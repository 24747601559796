import axios from 'axios'
import WooCommerce from '@/api/woocommerce'

export default {
  baseUrl(lang = 'en') {
    return `https://api.sitelle.ua/${lang}/wp-json/wp/v2/`
  },
  apiCart:
    process.env.NODE_ENV === 'production'
      ? `https://${process.env.baseUrl}/api/wp-json/cocart/v1/`
			: `http://${process.env.baseUrl}/api/wp-json/cocart/v1/`,

	// apiCart: 'https://api.sitelle.ua/api/wp-json/cocart/v1/',

  /**
   * GET Wordpress page by slug
   * @param {string} slug - find page by slug
   * @param {string} lang - request to page language
   * @returns { object } - page data
   */
  async getPage(slug, lang = 'en') {
    try {
      const response = await axios.get(
        `${this.baseUrl(lang)}pages?slug=${slug}/`
      )
      const data = response.data[0]
      const filtered = {
        title: data.title,
        content: data.content,
        yoast_meta: data.yoast_meta
      }

      return filtered
    } catch (error) {
      console.log(error)
    }
  },

  /**
   * GET Wordpress front page
   * @param {string} lang - request to page language
   * @return { object } - front page data
   */
  async getFrontPage(lang = 'en') {
    try {
      const response = await axios.get(
        `${this.baseUrl(lang)}pages?slug=front-page`
      )
      const data = response.data[0]
      const filtered = {
        acf: data.acf,
        yoast_meta: data.yoast_meta
      }

      return filtered
    } catch (error) {
      console.log(error)
    }
  },

  /**
   * GET Wordpress "About us" page
   * @param { string } lang - request to page language
   * @returns { object } - "About us" page data
   */
  async getAboutPage(lang = 'en') {
    try {
      const response = await axios.get(
        `${this.baseUrl(lang)}pages?slug=about-us/`
      )
      const data = response.data[0]
      const filtered = {
        acf: data.acf,
        content: data.content,
        yoast_meta: data.yoast_meta
      }

      return filtered
    } catch (error) {
      console.log(error)
    }
  },

  /**
   * GET Wordpress "Boutiques" page
   * @param { string } lang - request to page language
   * @returns { object } - "Boutiques" page data
   */
  async getBoutiquesPage(lang = 'en') {
    try {
      const response = await axios.get(
        `${this.baseUrl(lang)}pages?slug=boutiques/`
      )
      const data = response.data[0]
      const filtered = {
        acf: data.acf,
        title: data.title,
        content: data.content,
        yoast_meta: data.yoast_meta
      }

      return filtered
    } catch (error) {
      console.log(error)
    }
  },

  /**
   * GET Wordpress "Common content" page
   * @param { string } lang - request to page language
   * @returns { object } - "Common content" page data
   */
  async getContent(lang = 'en') {
    try {
      const response = await axios.get(
        `${this.baseUrl(lang)}pages?slug=common-content/`
      )
      const data = response.data[0].acf

      return data
    } catch (error) {
      console.log(error)
    }
  },

  /**
   * GET Woocommerce products
   * @param { string } lang - request to page language
   * @param { integer } currency - currency for specific country
   * @returns { array } - list of all products
   */
  async getProducts(lang = 'en', currency = 'USD') {
    try {
      const response = await WooCommerce.getAsync(
        `products?per_page=100&status=publish&lang=${lang}&currency=${currency}`
      )
      const data = JSON.parse(response.body)

      return data
    } catch (error) {
      console.log(error)
    }
  },

  /**
   * GET Woocommerce category
   * @param { string } slug - find category by slug
   * @param { string } lang - request to page language
   * @returns { object } - category data
   */
  async getCategory(slug, lang = 'en') {
    try {
      const response = await WooCommerce.getAsync(
        `products/categories?slug=${slug}&lang=${lang}`
      )
      const data = JSON.parse(response.body)

      return data
    } catch (error) {
      console.log(error)
    }
  },

  /**
   * GET Woocommerce category products
   * @param { string } slug - find products by category slug
   * @param { string } lang - request to page language
   * @param { integer } currency - currency for specific country
   * @returns { array } - list of category products
   */
  async getCategoryProducts(slug, lang = 'en', currency = 'USD') {
    try {
      const response = await WooCommerce.getAsync(
        `products?by_category=${slug}&status=publish&per_page=100&lang=${lang}&currency=${currency}`
      )
      const data = JSON.parse(response.body)

      return data
    } catch (error) {
      console.log(error)
    }
  },

  /**
   * GET Woocomerce products by search
   * @param { string } keyword - search keyword
   * @param { string } lang - request to page language
   * @param { integer } currency - currency for specific country
   * @returns { array } - list of finded products
   */
  async searchProducts(keyword, lang = 'en', currency = 'USD') {
    try {
      const response = await WooCommerce.getAsync(
        `products?search=${keyword}&status=publish&per_page=100&lang=${lang}&currency=${currency}`
      )
      const data = JSON.parse(response.body)

      return data
    } catch (error) {
      console.log(error)
    }
  },

  /**
   * GET Woocomerce single products
   * @param { string } slug - find product by slug
   * @param { string } lang - request to page language
   * @param { integer } currency - currency for specific country
   * @returns { object } - product data
   */
  async getSingleProduct(slug, lang = 'en', currency = 'USD') {
    try {
      const response = await WooCommerce.getAsync(
        `products?slug=${slug}&lang=${lang}&currency=${currency}`
      )
      const data = JSON.parse(response.body)

      return data
    } catch (error) {
      console.log(error)
    }
  },

  /**
   * GET Woocomerce last 4 products
   * @param { string } lang - request to page language
   * @param { integer } currency - currency for specific country
   * @returns { array } - list of last 4 products
   */
  async getLatestProducts(lang = 'en', currency = 'USD') {
    try {
      const response = await WooCommerce.getAsync(
        `products?per_page=4&status=publish&lang=${lang}&currency=${currency}`
      )
	  const data = JSON.parse(response.body)
	  
	  console.log(data);

      return data
    } catch (error) {
      console.log(error)
    }
  },

  /**
   * GET CoCart products
   * @returns { array } - list of all products in cart
   */
  async getCartProducts(currency = 'USD') {
    try {
      const response = await axios.get(
        `${this.apiCart}get-cart?currency=${currency}`
      )
      const data = response.data

      return data
    } catch (error) {
      console.log(error)
    }
  },

  /**
   * GET CoCart totals
   * @returns { object } - cart totals data
   */
  async getCartTotals(currency = 'USD') {
    try {
      const response = await axios.get(
        `${this.apiCart}totals?currency=${currency}`
      )
      const data = response.data

      return data
    } catch (error) {
      console.log(error)
    }
  },

  /**
   * GET Products count in CoCart
   * @returns { integer } - number of products in cart
   */
  async countCartItems() {
    try {
      const response = await axios.get(`${this.apiCart}count-items`)
      const data = response.data

      return data
    } catch (error) {
      console.log(error)
    }
  },

  /**
   * POST Add product to CoCart
   * @param { integer } id - id of product
   * @param { string } slug - slug of product
   * @param { string } image - link to product image
   * @param { integer } size - selected size of product
   */
  async addToCart(id, variation, slug, image) {
    try {
      const response = await axios.post(`${this.apiCart}add-item`, {
        product_id: id,
        quantity: 1,
        variation_id: variation.variation_id,
        variation: variation,
        cart_item_data: {
          slug: slug,
          image: image
        },
        refresh_totals: true,
        return_cart: true
      })
      const data = response.data

      return data
    } catch (error) {
      console.log(error)
    }
  },


  async clearCart() {
    try {
      const response = await axios.post(
        `${this.apiCart}clear`,
        {
        headers: {
          'Content-Type': 'x-www-form-urlencoded'
        }
        }
      )
      const data = response.data
    
      return data
      } catch (error) {
      console.log(error)
    }
  },

  /**
   * POST Update product quantity in CoCart
   * @param { integer } key - specific key of cart
   * @param { integer } quantity - quantity number
   */
  async updateCart(key, quantity) {
    try {
      const response = await axios.post(
        `${
          this.apiCart
        }item?cart_item_key=${key}&quantity=${quantity}&refresh_totals=true&return_cart=true`
      )
      const data = response.data

      return data
    } catch (error) {
      console.log(error)
    }
  },

  /**
   * DELETE Remove product from CoCart
   * @param { integer } key - specific key of cart
   */
  async removeFromCart(key) {
    try {
      const response = await axios.delete(
        `${this.apiCart}item?cart_item_key=${key}&return_cart=true`,
        {
          headers: {
            'Content-Type': 'x-www-form-urlencoded'
          }
        }
      )
      const data = response.data

      return data
    } catch (error) {
      console.log(error)
    }
  },

  /**
   * POST Add discount coupon to CoCart
   * @param {*} coupon - coupon
   */
  async addCoupon(coupon) {
    try {
      const response = await axios.post(`${this.apiCart}coupon`, {
        coupon: coupon
      })

      return response;
    } catch (error) {
      console.log(error)
    }
	},
	
	async checkCoupon() {
		try {
      const response = await axios.get(`${this.apiCart}coupon`)

      return response;
    } catch (error) {
      console.log(error)
    }
	},

  /**
   * DELETE Remove discount coupon from CoCart
   */
  async removeCoupon() {
    try {
      const response = await axios.delete(`${this.apiCart}coupon`)
      const data = response.data

      return data
    } catch (error) {
      console.log(error)
    }
  },

  sendContactForm(formId, formData, lang) {
    return new Promise((resolve, reject) => {
      axios.defaults.baseURL = `https://api.sitelle.ua/${lang}`
      // получаем форму
      axios
        .post(
          `/wp-json/contact-form-7/v1/contact-forms/${formId}/feedback`,
          formData
        )
        .then(response => {
          const data = response.data

          if (
            response.status === 200 &&
            response.data.status !== 'mail_failed'
          ) {
            resolve(data)
          } else {
            resolve(response.data.status)
          }
        })
    })
  },
}
