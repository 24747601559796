<template lang="pug">
  .wrapper.no-sidebar(:class="$mq")
    MainHeader
    .container
      MainSidebar
      main.main-content
        nuxt
    MainFooter
    Search
    CountryModal
</template>

<script>
import { mapState } from 'vuex'
import MainHeader from '@/components/partials/MainHeader'
import MainFooter from '@/components/partials/MainFooter'
import MainSidebar from '@/components/partials/MainSidebar'
import Search from '@/components/partials/Search'
import CountryModal from '@/components/partials/CountryModal'

export default {
  components: { MainHeader, MainFooter, MainSidebar, Search, CountryModal },

  computed: {
    ...mapState(['showSearch'])
  },

  head() {
    return {
      bodyAttrs: {
        class: this.showSearch ? 'ovh' : ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
