import Vue from 'vue'
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'

Vue.use(Viewer, {
  defaultOptions: {
    zIndex: 10000,
    button: false,
    title: false,
    toolbar: false,
    fullscreen: false,
    movable: false,
    zoomable: false,
    rotatable: false,
    scalable: false,
    transition: false
  }
})
