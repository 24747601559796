<template lang="pug">
  nav.main-menu
    ul.main-menu__list
      li(v-for="(item, index) in menu" :key="index")
        nuxt-link(:to="localePath({ name: item.slug, params: { slug: item.slug } }) + '/'") {{ item.title }}

        ul(v-if="item.slug == 'catalog'")
          li(v-for="(subItem, index) in mainNav" :key="index")
            nuxt-link(:to="localePath({ name: 'product-category-slug', params: { slug: returnSlug(subItem.slug) } })  + '/'") {{ subItem.name }}
</template>

<script>
import { mapState } from 'vuex'

export default {
  data() {
    return {
      menu: [
        {
          title: this.$t('mainMenu.item1'),
          slug: 'catalog',
        },
        {
          title: this.$t('mainMenu.item2'),
          slug: 'about-us'
        },
        {
          title: this.$t('mainMenu.item3'),
          slug: 'boutiques'
        },
        {
          title: this.$t('mainMenu.item4'),
          slug: 'contacts'
        }
      ]
    }
  },

  computed: {
    ...mapState({
      mainNav: state => state.content.data.categories_nav
    })
  },

  methods: {
    returnSlug(slug) {
      let lastIndex = slug.lastIndexOf("-");
      let requiredPath = slug.slice(0, lastIndex);

      return requiredPath;
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~assets/scss/helpers/vars';

.main-menu {
  &__list {
    li {
      word-break: break-word;
    }

    a {
      display: inline-block;
      color: $blackColor;
      font-size: 14px;
      font-weight: 600;
      line-height: 1.4;

      &.nuxt-link-exact-active {
        color: rgba($blackColor, 0.5);
      }
    }

    > li {
      > a {
        text-transform: uppercase;
      }
    }

    li {
      margin-bottom: 10px;
      line-height: 1;

      &:last-child {
        margin-bottom: 0;
      }

      > ul {
        margin-top: 15px;
        margin-left: 10px;

        > li {
          margin-bottom: 10px;
          line-height: 1;

          &:last-child {
            margin-bottom: 0;
          }
        }

        a {
          font-family: $avenirFont;
          font-weight: 500;
        }
      }
    }
  }
}
</style>
