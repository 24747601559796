import api from '@/api/index'

export const state = () => ({
  products: {},
  totals: {},
  count: 0
})

export const getters = {}

export const mutations = {
  SET_PRODUCTS(state, payload) {
    state.products = payload
  },

  SET_TOTALS(state, payload) {
    state.totals = payload
  },

  SET_COUNT(state, payload) {
    state.count = payload
  }
}

export const actions = {
  async GET_TOTALS({ commit }, { currency }) {
    commit('SET_TOTALS', await api.getCartTotals(currency))
  },

  async GET_COUNT({ commit }) {
    commit('SET_COUNT', await api.countCartItems())

    console.log('Cart counted')
  },

  async GET_PRODUCTS({ commit }, { currency }) {
    const response = await api.getCartProducts(currency)

    if (response && Object.entries(response).length) {
      commit('SET_PRODUCTS', response.items)
      commit('SET_COUNT', response.items_counted)
    } else {
      commit('SET_PRODUCTS', [])
      commit('SET_COUNT', 0)
    }
  },

  async ADD_PRODUCT({ commit }, { id, variation, slug, image }) {
    const response = await api.addToCart(id, variation, slug, image)

    if (response && Object.entries(response).length) {
      commit('SET_PRODUCTS', response.items)
      commit('SET_COUNT', response.items_counted)
    } else {
      commit('SET_PRODUCTS', [])
      commit('SET_COUNT', 0)
    }

    console.log('Product added')
  },

  async UPDATE_PRODUCT({ commit }, { key, quantity }) {
    const response = await api.updateCart(key, quantity)

    if (response && Object.entries(response).length) {
      commit('SET_PRODUCTS', response.items)
      commit('SET_COUNT', response.items_counted)
    } else {
      commit('SET_PRODUCTS', [])
      commit('SET_COUNT', 0)
    }

    console.log(`Product ${key} was updated to ${quantity}`)
  },

  async REMOVE_PRODUCT({ commit }, { key }) {
    const response = await api.removeFromCart(key)

    if (response && Object.entries(response).length) {
      commit('SET_PRODUCTS', response.items)
      commit('SET_COUNT', response.items_counted)
    } else {
      commit('SET_PRODUCTS', [])
      commit('SET_COUNT', 0)
    }

    console.log(`Product ${key} has removed`)
  }
}
