<template lang="pug">
	transition(name="page")
		.s-modal.order-fitting(v-if="showOrderFittingModal")
			.s-modal__overlay(@click="closeModal()")
			.s-modal__wrapper
				.s-modal__close(@click="closeModal()")
					svg-icon.icon-close-cursor(name="close-cursor")
				
				p.order-fitting__success(v-if="formSent") {{ $t('modals.orderFitting.thanks') }}
				
				.order-fitting__body(v-if="formSent === false")
					h2.s-modal__title {{ $t('modals.orderFitting.title') }}
					form.order-fitting__form(@submit.prevent="sendForm($event)")
						.order-fitting__preloader(v-show="formPreloader")
						.form-group
							input.form-input(:class="{ 'filled': fullName }"
															id="orderFitting-fullName"
															type="text" name="fullName"
															v-model="fullName"
															v-validate="'alpha_spaces|required'")
							label.form-label(for="orderFitting-fullName") {{ $t('forms.fullName') }}
							span.form-error(v-show="errors.has('fullName')") {{ $t('forms.errors.fullName') }}
						.form-group
							input.form-input(:class="{ 'filled': phone }"
																id="orderFitting-phone"
																type="text" name="phone"
																v-model="phone"
																v-validate="'required|numeric'")
							label.form-label(for="orderFitting-phone") {{ $t('forms.phone') }}
							span.form-error(v-show="errors.has('phone')") {{	$t('forms.errors.phone') }}
						.form-group
							input.form-input(:class="{ 'filled': email }"
															id="orderFitting-email"
															type="email" name="email"
															v-model="email"
															v-validate="'required|email'")
							label.form-label(for="orderFitting-email") {{ $t('forms.email') }}
							span.form-error(v-show="errors.has('email')") {{ $t('forms.errors.email') }}
						button.btn(type="submit")
							span {{ $t('modals.orderFitting.order') }}
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import api from '~/api/index'

export default {
  data() {
    return {
      fullName: '',
      email: '',
      phone: '',
      formPreloader: '',
      formSent: false
    }
  },
  computed: {
    ...mapState(['showOrderFittingModal'])
  },

  watch: {
    $route: function() {
      if (this.showOrderFittingModal) {
        this.closeModal()
      }
    }
  },

  methods: {
    ...mapMutations({
      closeModal: 'TOOGLE_ORDER_FITTING_MODAL'
    }),

    sendForm({ params }, callback) {
      this.$validator.validate().then(valid => {
        if (valid) {
          this.formPreloader = true

          const OrderFittingData = new FormData()

          OrderFittingData.set('FullName', this.fullName)
          OrderFittingData.set('Phone', this.phone)
          OrderFittingData.set('Email', this.email)
          OrderFittingData.set('Url', window.location.href)

          api
            .sendContactForm(9, OrderFittingData, this.$cookies.get('locale'))
            .then(res => {
              this.formPreloader = false
              this.formSent = true
            })
        } else {
          this.$el.querySelector('[aria-invalid=true]').focus()
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~assets/scss/helpers/vars';
@import '~assets/scss/helpers/mixins';

.order-fitting {
  &__form {
    position: relative;
  }

  &__list {
    list-style-type: decimal;
    text-align: initial;
    list-style-position: inside;
    margin-bottom: 20px;
  }

  &__preloader {
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $whiteColor;
    background-image: url('/img/preloader_search.svg');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }

  &__success {
    font-size: 32px;

    @include mobile {
      display: flex;
      height: 100%;
      align-items: center;
    }
  }
}
</style>
