import requestIp from 'request-ip'
import api from '@/api/index'
import countriesList from '@/assets/json/countries.json'

export const state = () => ({
  showSearch: false,
  showMobileMenu: false,
  showMobileZoom: false,
  showCountryModal: false,
  showOrderFittingModal: false,
  geoData: {
    country: '',
    locale: '',
    currency: ''
	}
})

export const getters = {}

export const mutations = {
  TOOGLE_SEARCH(state) {
    if (state.showSearch) {
      state.showSearch = false
    } else {
      state.showSearch = true
    }
  },

  TOOGLE_MOBILE_MENU(state) {
    if (state.showMobileMenu) {
      state.showMobileMenu = false
    } else {
      state.showMobileMenu = true
    }
  },

  TOOGLE_MOBILE_ZOOM(state) {
    if (state.showMobileZoom) {
      state.showMobileZoom = false
    } else {
      state.showMobileZoom = true
    }
  },

  TOOGLE_COUNTRY_MODAL(state) {
    if (state.showCountryModal) {
      state.showCountryModal = false
    } else {
      state.showCountryModal = true
    }
  },

  TOOGLE_ORDER_FITTING_MODAL(state) {
    if (state.showOrderFittingModal) {
      state.showOrderFittingModal = false
    } else {
      state.showOrderFittingModal = true
    }
  },

  SET_GEO_DATA(state, payload) {
    state.geoData.country = payload.country
    state.geoData.locale = payload.locale
    state.geoData.currency = payload.currency
  },

  SET_GEO_LOCALE(state, payload) {
    state.geoData.locale = payload.locale
  }
}

export const actions = {
  async nuxtServerInit(
    { commit, dispatch },
    { app, store, redirect, route, req }
  ) {
		/**
		 * Проверяем на прямую ссылку
		 */
		if (route.path.includes('/ru/') || route.path.includes('/uk/')) {
			let locale = route.path.includes('/ru/') ? 'ru' : 'uk';

			commit('SET_GEO_DATA', {
				country: 'Ukraine',     
				locale: locale,
				currency: 'UAH'
			});

			app.$cookies.set('country', 'Ukraine')
			app.$cookies.set('locale', locale)
			app.$cookies.set('currency', 'UAH')
		} else {
			/**
			 * Данные страны пользователя по ip, запись данных в куки, "nuxt i18n"-редирект
			 */
			// Запрос к сервису https://ipapi.co/
			if (!app.$cookies.get('country')) {
				await app.$axios
					.get(`https://ipapi.co/${requestIp.getClientIp(req)}/json/`)
					.then(response => {
						// Записываем значения запроса в переменную
						const data = response.data

						// Находим соответствие страны из запроса и страны из списка
						const countryExist = countriesList.find(
							item => item.country === data.country_name
						)

						// Если страна из запроса соответствует стране из списка
						if (countryExist) {
							// Запись названия страны в куки
							app.$cookies.set('country', countryExist.country)
							app.$cookies.set('locale', countryExist.locale)
							app.$cookies.set('currency', countryExist.currency)

							// Запись данных страны в VueX
							commit('SET_GEO_DATA', {
								country: countryExist.country,
								locale: countryExist.locale,
								currency: countryExist.currency
							})

							// Редирект на язык региона
							localeRedirect(app, store, route, redirect)
						} else {
							// Иначе записывыаем дефолтные данные в куки
							app.$cookies.set('country', 'Country')
							app.$cookies.set('locale', 'en')
							app.$cookies.set('currency', 'none')

							// Запись дефолтных данных в VueX
							commit('SET_GEO_DATA', {
								country: 'Country',
								locale: 'en',
								currency: 'none'
							})

							// Редирект на язык региона
							localeRedirect(app, store, route, redirect)
						}
					})
					.catch(error => {
						console.log(error)
					})
			} else {
				// Запись данных страны в VueX
				commit('SET_GEO_DATA', {
					country: app.$cookies.get('country'),
					locale: app.$cookies.get('locale'),
					currency: app.$cookies.get('currency')
				})

				// Редирект на язык региона
				localeRedirect(app, store, route, redirect)
			}
		}

    /**
     * Общий контент для сайта
     */
    commit(
      'content/SET_CONTENT',
      await api.getContent(store.state.geoData.locale)
    )

    /**
     * Кол-во товаров в корзине
     */
    await dispatch('cart/GET_COUNT')
  }
}

/**
 * Функция для редиректа на локаль по региону
 */
function localeRedirect(app, store, route, redirect) {
  const i18nLocale = app.i18n.locale // Локаль nuxt-i18n
  const storeLocale = store.state.geoData.locale // Локаль во VueX

  // Если локаль существует во VueX
  if (storeLocale) {
    // Если локаль nuxt-i18n не совпадает с локалью во VueX (тогда делаем редирект)
    if (i18nLocale !== storeLocale) {
      const fullPath = route.fullPath // Полный путь к конретному роуту
      const fullPathSplit = fullPath.split('/') // Конвертим строку пути в массив (разделяем через "/")

      // Проверка на англ. локаль (так как она не выводиться в URL)
      if (storeLocale === 'en') {
        // Если это индексная страница (с локалями "uk" или "ru")
        if (fullPath === '/uk' || fullPath === '/ru') {
          const fullPathReplace = fullPath.replace(/\/ru|\/uk/g, '/') // Меняем путь на "/"

          // Редирект на нужный путь
          redirect(302, fullPathReplace)
        } else {
          // Для остальных страниц меняем Regexp
          const fullPathReplace = fullPath.replace(/\/ru\/|\/uk\//g, '/')

          // Редирект на нужный путь
          redirect(302, fullPathReplace)
        }
      } else {
        // Если это другая локаль (uk или ru)
        let fullPathReplace

        // Проверка на индексную страницу
        if (fullPath === '/') {
          // Подставляем на 2 место в пути нужную локаль ("/" в начале)
          fullPathReplace = fullPath.replace(
            fullPathSplit[1],
            `/${storeLocale}`
          )

          // Редирект на нужный путь
          redirect(302, fullPathReplace)
        } else if (fullPathSplit[1] === 'uk' || fullPathSplit[1] === 'ru') {
          // Для остальных страниц с локалями (uk, ru), подставляем на 2 место в пути нужную локаль (не ставим "/" в начале)
          fullPathReplace = fullPath.replace(fullPathSplit[1], storeLocale)

          // Редирект на нужный путь
          redirect(302, fullPathReplace)
        } else {
          // Иначе подставляем нужную локаль ("/" в начале)
          fullPathReplace = fullPath.replace(
            fullPathSplit[0],
            `/${storeLocale}`
          )

          // Редирект на нужный путь
          redirect(302, fullPathReplace)
        }
      }
    }
  }
}
