<template lang="pug">
  transition(name="page")
    .search(v-if="showSearch")
      .container
        a.search__close(href="#" @click.prevent="closeSearch()")
          span {{ $t('search.close') }}
          svg-icon.icon-close(name="close")

        .search__wrapper
          .search__input
            input(v-focus v-model.trim="searchQuery" @keyup="loadResult()"
                  type="search" :placeholder="$t('search.input')")

          .search__result
            transition(name="page")
              .search__result-preloader(v-if="preloader"
                                        :style="{ backgroundImage: `url('/img/preloader_search.svg')` }")
            transition(name="page")
              ProductsList(v-if="result.length > 0"
                           :products="result")
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import api from '@/api/index'
import ProductsList from '@/components/products/ProductsList'

export default {
  components: { ProductsList },

  data() {
    return {
      searchQuery: '',
      result: [],
      preloader: false
    }
  },

  computed: {
    ...mapState(['geoData', 'showSearch'])
  },

  watch: {
    $route: function() {
      if (this.showSearch) {
        this.closeSearch()
      }
    }
  },

  methods: {
    ...mapMutations({
      closeSearch: 'TOOGLE_SEARCH'
    }),

    loadResult() {
      // Если поисковый запрос не пустой тогда делаем запрос к серверу
      if (this.searchQuery !== '') {
        // Если вызываеться запрос очищаем таймер и выводим прелоадер
        if (this.timer) {
          clearTimeout(this.timer)
          this.timer = null
          this.result = []
          this.preloader = true
        }

        // Вызываем запрос после ввода текста в инпут с задержкой 500мс
        this.timer = setTimeout(() => {
          return new Promise((resolve, reject) => {
            api
              .searchProducts(
                this.searchQuery,
                this.geoData.locale,
                this.geoData.currency
              )
              .then(response => {
                this.preloader = false
                this.result = response
              })
          })
        }, 500)
      } else {
        // Иначе выводим пустой результат
        this.result = []
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~assets/scss/helpers/vars';
@import '~assets/scss/helpers/mixins';

.search {
  position: fixed;
  z-index: 1;
  top: 80px;
  left: 0;
  width: 100%;
  height: calc(100% - 80px);
  overflow-y: auto;
  background-color: #ffffff;

  @include tablet {
    top: 65px;
    height: calc(100% - 65px);
  }

  @include mobile {
    top: 52px;
    height: calc(100% - 52px);
  }

  > .container {
    position: relative;
  }

  &__close {
    position: absolute;
    top: 30px;
    right: 15px;
    font-size: 14px;
    font-weight: 500;

    @include mobile-small {
      font-size: 12px;
    }

    svg {
      margin-left: 5px;
    }
  }

  &__wrapper {
    height: 100%;
    max-width: 880px;
    margin: 0 auto;
    padding-top: 240px;

    @include laptop {
      padding-top: 200px;
    }

    @include tablet {
      padding-top: 150px;
    }

    @include mobile {
      padding-top: 100px;
    }

    @include mobile-small {
      padding-top: 80px;
    }
  }

  &__input {
    margin-bottom: 70px;

    @include tablet {
      margin-bottom: 50px;
    }

    @include mobile {
      margin-bottom: 0;
    }

    input[type='search'] {
      box-sizing: border-box;
      display: block;
      width: 100%;
      overflow: hidden;
      padding: 5px 0;
      font-family: $avenirFont, serif;
      color: $blackColor;
      font-size: 30px;
      font-weight: 500;
      line-height: 1.2;
      text-transform: uppercase;
      border: 0;
      border-bottom: 1px solid $greyColor;
      box-shadow: none;
      background-color: $whiteColor;
      outline: 0;
      transition: all 0.3s;

      @include laptop {
        font-size: 26px;
      }

      @include tablet {
        font-size: 20px;
      }

      @include mobile {
        font-size: 14px;
      }

      @include mobile-small {
        font-size: 12px;
      }

      &::placeholder {
        color: rgba($blackColor, 0.75);
      }

      &::-webkit-search-cancel-button {
        display: none;
      }
    }
  }

  &__result {
    position: relative;

    &-preloader {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      min-height: 300px;
      background-color: transparent;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 50%;
      font-size: 30px;
      font-weight: 600;
    }

    &-empty {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      min-height: 300px;
      font-size: 36px;
      font-weight: 600;
      text-align: center;
      text-transform: uppercase;
      background-color: $whiteColor;
    }
  }
}
</style>
