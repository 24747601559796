import Vue from 'vue'

Vue.directive('focus', {
  inserted: el => {
    el.focus()
  }
})

Vue.directive('show-on-scroll', {
  inserted: el => {
    window.onscroll = () => {
      if (window.scrollY > 400) {
        el.classList.add('show')
      } else {
        el.classList.remove('show')
      }
    }
  }
})
