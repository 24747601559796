<template lang="pug">
  .container
    main.main-content
      nuxt
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
</style>
