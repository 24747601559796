<template lang="pug">
  .wrapper(:class="$mq")
    MainHeader
    .container
      MainSidebar
      main.main-content
        nuxt
    MainFooter
    Search
    CountryModal
    OrderFittingModal
</template>

<script>
import lazybeforeunveil from 'lazysizes';

import { mapState } from 'vuex'
import MainHeader from '@/components/partials/MainHeader'
import MainFooter from '@/components/partials/MainFooter'
import MainSidebar from '@/components/partials/MainSidebar'
import Search from '@/components/partials/Search'
import CountryModal from '@/components/partials/CountryModal'
import OrderFittingModal from '@/components/partials/OrderFitting'


export default {
  components: {
    MainHeader,
    MainFooter,
    MainSidebar,
    Search,
    CountryModal,
    OrderFittingModal
  },

  computed: {
    ...mapState({
      showSearch: state => state.showSearch,
      showMobileMenu: state => state.showMobileMenu,
      showMobileZoom: state => state.showMobileZoom,
      showCountryModal: state => state.showCountryModal,
      showOrderFittingModal: state => state.showOrderFittingModal
    })
  },

  head() {
		let robots = {},
			defaultMarkup = {
				"@context": "https://schema.org",
				"@type": "Organization",
				"@id": "https://sitelle.ua/",
				"name": "SITELLE",
				"url": "https://sitelle.ua/",
				"telephone": "+380973690936",
				"logo": "https://sitelle.ua/img/logo.png",
				"contactPoint": [
					{ "@type": "ContactPoint",
						"telephone": "+38 097 369 09 36",
						"contactType": "customer service"
					}
				]
			},
			links = [{}];

    if (process.env.baseUrl !== 'sitelle.ua') {
      robots = { hid: 'robots', name: 'robots', content: 'noindex,nofollow' }
		}
		
		if (Object.keys(this.$route.query).length > 0) {
			links = [{ rel: "canonical", href: `​https://sitelle.ua${this.$route.path}` }]
		}

    return {
      meta: [
        robots
			],

			script: [
				{
					type: "application/ld+json",
					innerHTML: JSON.stringify(defaultMarkup),
				},
			],
			link: links.length ? links : [{}],
      bodyAttrs: {
        class:
          this.showSearch ||
          this.showMobileMenu ||
          this.showMobileZoom ||
          this.showCountryModal ||
          this.showOrderFittingModal
            ? 'ovh'
            : ''
			},
    }
  },

  beforeMount() {
    this.loadCartData();
  },

  mounted() {
    document.addEventListener('lazybeforeunveil', function(e) {
      const bg = e.target.getAttribute('data-bg')

      if (bg) {
        e.target.style.background = bg
      }
    })
  },

  methods: {
    async loadCartData() {
      const currency = this.$cookies.get('currency')

      await this.$store.dispatch('cart/GET_PRODUCTS', { currency })
      await this.$store.dispatch('cart/GET_TOTALS', { currency })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~assets/scss/helpers/mixins';

.main-content {
  width: 100%;
  max-width: calc(100% - 180px);
  margin-left: auto;

  @include tablet {
    max-width: 100%;
  }
}
</style>
