import WooCommerceAPI from 'woocommerce-api'

const URL =
  process.env.NODE_ENV === 'production'
    ? `https://${process.env.baseUrl}/api/`
		: `https://api.sitelle.ua/`
		
const CONSUMER_KEY = 'ck_15c5d9688e1a6e296c16d5f6b4aecd8fb9edd899'
const CONSUMER_SECRET = 'cs_7d77ff2311b528e2167efedd4c1aab9e4d073b0a'
const WC_VERSION = 'wc/v2'

export default new WooCommerceAPI({
  url: URL,
  consumerKey: CONSUMER_KEY,
  consumerSecret: CONSUMER_SECRET,
  wpAPI: true,
  version: WC_VERSION
})
