<template lang="pug">
	.products-list
		template(v-if="products.length > 0")
			.products-list__row
				.products-list__col(v-for="(product, index) in products" :key="index")
					article.product-list-item

						//- Cross sell products (by colors)
						.product-list-item__colors(v-if="product.cross_sell_ids.length > 0")
							ul.list
								li.list__item(v-for="(item, index) in product.cross_sell_ids" :key="index")
									nuxt-link(:to="localePath({ name: 'product-slug', params: { slug: replaceUrl(item.link) } })")
										img.lazyload(src="/img/preloader_search.svg"
																	:data-src="item.product_color_icon" 
																	:alt="item.name"
																	:title="$t('pages.catalog.productImageTitle') + item.name")

						nuxt-link(:to="localePath({ name: 'product-slug', params: { slug: product.slug } })")
							//- Product image
							.product-list-item__img(v-if="product.images && product.images[0]")
								img.lazyload(src="/img/preloader_search.svg"
															:data-src="product.images[0].woocommerce_thumbnail"
															:data-srcset="product.images[0].woocommerce_thumbnail + ' 1x,' + product.images[0].woocommerce_single + ' 2x'" 
															:alt="product.name"
															:title="$t('pages.catalog.productImageTitle') + product.name")

							//- Product content
							.product-list-item__content
								//- Sizes list
								.product-list-item__sizes
									ul.list(v-if="product.product_variations.length > 0")
										li.list__item(v-for="(size, index) in product.product_variations" :key="index")
											span(v-if="size.in_stock") {{ size.variation_attributes.attribute_pa_sizes.split('-')[0] }}
											span.disable(v-else) {{ size.variation_attributes.attribute_pa_sizes.split('-')[0] }}

								//- Title
								h3.product-list-item__title {{product.name }}

								//- Price
								template(v-if="$cookies.get('currency') !== 'none'")
									.product-list-item__price(v-if="product.price_html !== ''")
										.price(v-html="product.price_html")
										.on-sale(v-if="product.on_sale") Sale

		template(v-else)
			.products-empty
				h2.products-empty__title {{ $t('products.notFound') }}
</template>

<script>
export default {
  props: {
    products: {
      type: Array,
      default: () => []
    }
  },

  updated() {
      let images = document.querySelectorAll('.lazyloaded');

      // Set right src attribute when products updated (updated only data-src)
      [].forEach.call(images, function(image) {
        let bg = image.getAttribute('data-bg');

        if (image.getAttribute('data-src') != image.getAttribute('src') && !bg) {
          image.setAttribute('src', image.getAttribute('data-src'));
          image.setAttribute('srcset', image.getAttribute('data-srcset'));
        } else if(bg && image.style.backgroundImage != `url(${bg})`) {
          image.style.backgroundImage = `url(${bg})`;
        }
      });
  }
}
</script>

<style lang="scss">
@import '~assets/scss/helpers/vars';
@import '~assets/scss/helpers/mixins';

.products-list {
  padding: 45px 0 50px;

  @include mobile {
    padding-bottom: 20px;
  }

  @include mobile {
    padding: 30px 0 10px;
  }

  &__row {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-left: -25px;
    margin-right: -25px;

    @include tablet {
      margin-left: -15px;
      margin-right: -15px;
    }

    @include mobile {
      margin-left: -5px;
      margin-right: -5px;
    }
  }

  &__col {
    flex: 0 0 100%;
    max-width: 33.33%;
    margin-bottom: 50px;
    padding-left: 25px;
    padding-right: 25px;

    @include tablet {
      padding-left: 15px;
      padding-right: 15px;
    }

    @include mobile {
      max-width: 50%;
      padding-left: 5px;
      padding-right: 5px;
    }

    @include mobile {
      margin-bottom: 30px;
    }

    @include mobile-small {
      margin-bottom: 20px;
    }
  }
}

.product-list-item {
  position: relative;

  &__colors {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 10px 20px 0;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s;

    @include tablet {
      display: none;
    }

    .list {
      display: flex;
      flex-wrap: wrap;
      margin-left: -2.5px;
      margin-right: -2.5px;

      &__item {
        flex: 0 0 15px;
        max-width: 15px;
        padding: 2.5px;

        &:last-child {
          margin-right: 0;
        }

        a {
          display: block;
          transition: all 0.3s;

          &:hover,
          &.active {
            transform: scale(0.8);
          }
        }
      }
    }
  }

  &__img {
    margin-bottom: 40px;

    @include mobile {
      margin-bottom: 20px;
    }

    @include mobile {
      margin-bottom: 10px;
    }

    a {
      display: block;
    }

    img {
      margin: 0 auto;
    }
  }

  &__sizes {
    min-height: 22px;
    padding: 0 20px;
    margin-bottom: 20px;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s;

    @include laptop {
      padding: 0;
    }

    @include tablet {
      display: none;
    }

    .list {
      display: flex;
      flex-wrap: wrap;

      @include laptop {
        justify-content: space-between;
      }

      &__item {
        margin-right: 15px;

        @include laptop {
          margin-right: 0;
        }

        &:last-child {
          margin-right: 0;
        }

        .disable {
          opacity: 0.3;
        }
      }
    }
  }

  &__title {
		display: block;
    margin-bottom: 15px;
    font-size: 14px;
    font-weight: 500;

    @include mobile-small {
      margin-bottom: 10px;
      font-size: 12px;
    }
  }

  &__price {
    display: flex;
    flex-wrap: wrap;

    .price {
      letter-spacing: 0.58px;

      del {
        margin-right: 5px;
        opacity: 0.6;
      }

      ins {
        text-decoration: none;
      }
    }

    .on-sale {
      flex: 0 0 24px;
      width: 24px;
      height: 24px;
      margin-left: 10px;
      color: $whiteColor;
      font-size: 11px;
      line-height: 24px;
      font-style: italic;
      text-align: center;
      border-radius: 50%;
      background-color: $redColor;
    }
  }

  &:hover {
    .product-list-item__colors,
    .product-list-item__sizes {
      opacity: 1;
      visibility: visible;
    }
  }
}

.products-empty {
  &__title {
    font-size: 36px;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;

    @include mobile {
      margin-top: 20px;
      font-size: 24px;
    }
  }
}

// Single product (latest products)
.latest-products {
  .products-list {
    padding: 0;

    &__row {
      @include desktop {
        margin-left: -15px;
        margin-right: -15px;
      }
    }

    &__col {
      @include desktop {
        padding: 0 15px;
      }

      @include mobile-small {
        max-width: 100%;
      }
    }
  }
}
</style>
