import Vue from 'vue'

Vue.mixin({
  methods: {
    replaceUrl(url) {
      return url
        .split('/')
        .filter(v => v !== '')
        .pop()
    },

    currencySymbol(price) {
      if (this.$cookies.get('currency') === 'USD') {
        return `&#36; ${price}`
      } else if (this.$cookies.get('currency') === 'EUR') {
        return `&euro; ${price}`
      } else if (this.$cookies.get('currency') === 'UAH') {
        return `${price} грн.`
      } else {
        return ''
      }
    }
  }
})
