<template lang="pug">
  header.main-header
    .container
      .main-header__row
        //- Mobile hamburger
        .main-header__hamburger(@click="openMobileMenu()")
          svg-icon.icon-hamburger(name="hamburger")

        //- Left content
        .main-header__contacts
          a.main-header__contacts-link.phone(v-if="$cookies.get('locale') != 'en'" href="tel: +38 097 369 09 36") +38 097 369 09 36
          a.main-header__contacts-link.mail(href="mailto: contact@sitelle.ua") contact@sitelle.ua

        //- Logo
        .main-header__logo
          nuxt-link(:to="$cookies.get('locale') === 'en' ? localePath('index') : localePath('index') + '/'")
            img(src="/img/main-logo.svg" :alt="$t('header.logoImageTitle')" :title="$t('header.logoImageTitle')")

        //- Right content
        .main-header__info

          //- Language
          .main-header-lang
            template(v-if="$cookies.get('country') === 'Ukraine'")
              template(v-if="$cookies.get('locale') === 'uk'")
                span.main-header-lang__link.active UA
              template(v-else)
                a.main-header-lang__link(:href="switchLocalePath('uk') + '/'" @click="$cookies.set('locale', 'uk')") UA

              template(v-if="$cookies.get('locale') === 'ru'")
                span.main-header-lang__link.active RU
              template(v-else)
                a.main-header-lang__link(:href="switchLocalePath('ru') + '/'" @click="$cookies.set('locale', 'ru')") RU

            template(v-else)
              span.main-header-lang__link.active EN

          //- Location
          .main-header-location
            span(v-if="$cookies.get('country')"
                 @click="openCountryModal()") {{ $cookies.get('country') }}
            span(v-else
                 @click="openCountryModal()") Country

          //- Search
          a.main-header-search(href="#" @click.prevent="openSearch()")
            span.text {{ $t('header.search') }}
            svg-icon.icon-search(name="search")

          //- Bag
          template(v-if="$cookies.get('country') !== 'Country'")
            nuxt-link.main-header-bag(:to="localePath('cart') + '/'")
              span.text {{ $t('header.bag') }}
              span.count {{ cartCount }}
          template(v-else)
            a.main-header-bag(href="#" @click.prevent="openCountryModal()")
              span.text {{ $t('header.bag') }}
              span.count 0
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  computed: {
    ...mapState({
      cartCount: state => state.cart.count
    })
  },

  methods: {
    ...mapMutations({
      openSearch: 'TOOGLE_SEARCH',
      openMobileMenu: 'TOOGLE_MOBILE_MENU',
      openCountryModal: 'TOOGLE_COUNTRY_MODAL'
    })
  }
}
</script>

<style lang="scss" scoped>
@import '~assets/scss/helpers/vars';
@import '~assets/scss/helpers/mixins';

.main-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  padding: 21.5px 0;
  border-top: 3px solid $blackColor;
  background-color: $yellowColor;
  user-select: none;

  @include tablet {
    padding: 15px 0;
  }

  @include mobile {
    padding: 12.5px 0;
  }

  &__row {
    position: relative;
  }

  &__hamburger {
    display: none;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    justify-content: center;
    align-items: center;
    cursor: pointer;

    @include tablet {
      display: flex;
    }
  }

  &__contacts {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0,-50%);
    &-link {
      font-size: 12px;
      color: #1D1300;
      font-weight: 500;
      line-height: 1.6;
      position: relative;
      margin-right: 50px;
      &:first-of-type {
        margin-left: 25px;
      }
      &:before {
        content: '';
        position: absolute;
        left: -25px;
        top: 50%;
        transform: translate(0,-50%);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        display: block;
        width: 15px;
        height: 15px;
      }
      &.phone {
        &:before {
          background-image: url(/img/phone-icon.svg);
        }
      }
      &.mail {
        &:before {
          background-image: url(/img/mail-icon.svg);
        }
      }
    }

    @include tablet {
      display: none;
    }

    @include mobile {
      display: none;
    }
  }

  &__logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 200px;
    line-height: 0;

    @include tablet {
      width: 160px;
    }

    @include mobile {
      width: 120px;
    }

    a {
      display: block;
    }
  }

  &__info {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    max-width: 300px;
    margin-left: auto;
    padding: 4px 0;

    @include tablet {
      max-width: 270px;
      padding: 0;
    }

    @include mobile {
      max-width: 80px;
    }

    .main-header-lang {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      padding-right: 5px;
      margin-right: 5px;

      @include tablet {
        display: none;
      }

      &:after {
        content: '';
        position: absolute;
        top: 8px;
        right: 0;
        display: block;
        width: 2px;
        height: 2px;
        border-radius: 50%;
        background-color: $blackColor;
      }

      &__link {
        margin-right: 3px;
        font-size: 12px;
        color: rgba($blackColor, 0.5);
        font-weight: 500;
        line-height: 1.6;
        text-transform: uppercase;
        transition: color 0.3s;

        &:last-child {
          margin-right: 0;
        }

        &:hover {
          color: rgba($blackColor, 0.5);
        }

        &.active {
          color: $blackColor;

          &:hover {
            color: $blackColor;
          }
        }
      }
    }

    .main-header-location {
      font-size: 12px;
      font-weight: 500;
      line-height: 1.6;
      margin-right: auto;
      cursor: pointer;

      @include tablet {
        display: none;
      }

      span {
        display: block;
        border-bottom: 1px solid $blackColor;
        transition: border-color 0.3s;

        &:hover {
          border-color: transparent;
        }
      }
    }

    .main-header-search {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      @include tablet {
        padding: 10px;
      }

      .text {
        margin-right: 5px;
        line-height: 1.35;

        @include tablet {
          display: none;
        }
      }
    }

    .main-header-bag {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-left: 30px;

      @include tablet {
        margin-left: 20px;
      }

      @include mobile {
        margin-left: 5px;
      }

      .text {
        margin-right: 10px;
        font-weight: 500;
        line-height: 1.35;

        @include tablet {
          display: none;
        }
      }

      .count {
        flex: 0 0 26px;
        width: 26px;
        height: 26px;
        border-radius: 50%;
        background-color: $blueColor;
        color: #fff0bf;
        font-size: 15px;
        line-height: 26px;
        text-align: center;

        @include tablet {
          flex: 0 0 24px;
          width: 24px;
          height: 24px;
        }
      }
    }
  }
}
</style>
