<template lang="pug">
  .main-footer-bottom__col.main-footer-bottom__col--thumb
    nav.main-footer-nav
      .main-footer-nav__title {{ $t('footer.menu1.title') }}
      ul.main-footer-nav__list
        li.main-footer-nav__list-item(v-for="(item, index) in menu1" :key="index")
          nuxt-link(:to="localePath({ name: 'slug', params: { slug: item.slug } }) + '/'" v-html="item.title")

    nav.main-footer-nav
      .main-footer-nav__title {{ $t('footer.menu2.title') }}
      ul.main-footer-nav__list
        li.main-footer-nav__list-item(v-for="(item, index) in menu2" :key="index")
          nuxt-link(:to="localePath({ name: 'slug', params: { slug: item.slug } }) + '/'" v-html="item.title")
</template>

<script>
export default {
  data() {
    return {
      menu1: [
        {
          title: this.$t('footerMenu.menu1.item1'),
          slug: 'payment-security'
        },
        {
          title: this.$t('footerMenu.menu1.item2'),
          slug: 'shipping'
        },
        {
          title: this.$t('footerMenu.menu1.item3'),
          slug: 'return-refunds'
				},
				{
          title: this.$t('footerMenu.menu1.item4'),
          slug: 'sitemap'
				},
      ],

      menu2: [
        {
          title: this.$t('footerMenu.menu2.item1'),
          slug: 'terms-conditions'
        },
        {
          title: this.$t('footerMenu.menu2.item2'),
          slug: 'privacy-policy'
        },
        {
          title: this.$t('footerMenu.menu2.item3'),
          slug: 'cookie-policy'
        }
      ]
    }
  }
}
</script>
